import React from "react";


const MapScreen = ({width=500, height=500, query}) => {

    return (
        <>
        <p>Map: </p>
        <iframe style={{marginTop: "15px"}} width={width} height={height} id="gmap_canvas" src={`https://maps.google.com/maps?q=${query}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">

        </iframe>
        </>
    )
}

export default MapScreen;