import React, { useState } from "react";
import { US_CODE } from "../code";
import MapScreen from "./map";
// import { type } from "os";

const CODE = US_CODE;

// function for dynamic sorting
function compareValues(key, order = "asc") {
  return function(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export default () => {
  const [state, setState] = useState({ key: "" });
  const [counties, setCounties] = useState([]);
  const [county, setCounty] = useState({ name: "" });
  const [city, setCity] = useState({ name: "", zip_code: "" });
  const [cities, setCities] = useState([]);

  const [query, setQuery] = useState("")


  const loadState = (state) =>{
    setState(state);
    setQuery(state.name)
    state.counties.sort(compareValues("name"));
    setCounties(state.counties);
    let cities = state.counties.map(c => c._cities);
    cities = [].concat(...cities);
    cities.sort(compareValues("name"));

    setCities([...cities]);
    setCounty({ name: "" });
    setCity(cities[0]);
  }

  return (
    <>
      <form className="form">
        <div className="form-group">
          <label>State:</label>
          <select
            className="form-control"
            name="state"
            onChange={e => {
              if (!e.target.value) return;
              const _state = CODE.find(k => k.key === e.target.value);
              console.log(_state)
              loadState(_state)
              
            }}
            value={state.key}
          >
            <option value="">-- SELECT STATE --</option>

            {CODE.map((s, k) => {
              return (
                <option key={k} value={s.key}>
                  {s.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>County:</label>

          <select
            className="form-control"
            name="county"
            onChange={e => {
              if (!e.target.value) return; 
              if (e.target.value =="reset") { 
                loadState(state)
                console.log(state)
              return; }


              const _county = counties.find(k => k.name === e.target.value);
              const _cities = _county._cities;
              setCounty(_county);
              
              setQuery(`${_county.name}, ${state.name}`)
              _cities.sort(compareValues("name"));

              setCities(_cities);
              setCity({ name: "", zip_code: "" });
            }}
            value={county.name}
          >
            <option value="reset">-- SELECT COUNTY --</option>

            {counties &&
              counties.map((s, k) => {
                return (
                  <option key={k} value={s.name}>
                    {s.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="form-group">
          <label>City:</label>

          <select
            className="form-control"
            name="city"
            onChange={e => {
              if (!e.target.value) return;

              const _city = cities.find(k => k.name === e.target.value);
              setCity(_city);

              const zips = _city.zip_code.split(",")

              setQuery(`${_city.name}, ${county.name}, ${state.name} ${zips[0]}`)

            }}
            value={city.name}
          >
            <option value="">-- SELECT CITY --</option>

            {cities &&
              cities.map((s, k) => {
                return (
                  <option key={k} value={s.name}>
                    {s.name}
                  </option>
                );
              })}
          </select>
        </div>
      </form>
      <div className="main">
        <section className="edu-exp" id="edu-exp">
          <div className="inner-sec">
            <div className="edu-exp-grids">
              <p>State: {state.name}</p>
              <p>County: {county.name || city.county}</p>
              <p>City: {city.name}</p>
              <p>Zip Code: {city.zip_code}</p>
              {city.zip_code ? (
                <>
                  <p>Fips Code: {city.fips_code}</p>
                  <p>Area Code: {city.area_code}</p>
                  <p>Time Zone: {city.time_zone}</p>
                </>
              ) : null}
              {state.name && <MapScreen query={query} /> }
              
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
