import React from "react";
import "./App.css";
// import Header from "./layouts/header";
import Footer from "./layouts/footer";
// import Article from "./layouts/article";
import Index from "./page/index";

function App() {
  return (
    <div className="wrap" id="home">
      <Index />
    </div>
  );
}

export default App;
