import React from "react";
import Form from "../form";

import AdSense from 'react-adsense';

export default () => {
  const client = "ca-pub-1687287790943251"
  const slot = "1445152222"

  return (
    <div className="my-lg-4 mb-4 px-2" >
      <div className="row">
        <div className="col-lg-3 hide-sm">
        <AdSense.Google
          client={client}
          slot={slot}
          style={{ width: "300px", height: 600, float: 'left' }}
          format=''
        />
        </div>
        <div className="col-sm-12 col-lg-6 mx-auto">
      <Form />

        </div>
        <div className="col-lg-3 hide-sm">
        <AdSense.Google
          client={client}
          slot={slot}
          style={{ width: 300, height: 600, float: 'left' }}
          format=''
        />
        </div>
      </div>
    </div>
  );
};
